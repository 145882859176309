import React from "react";
import Container from "../components/Container";
import styled from "styled-components";
import { Link } from "gatsby";

const AppContent = styled.main`
  overflow: hidden;
  padding-bottom: 48px;
`;

const Content = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 20px 0 0;
`;

const PageTitle = styled.div`
  padding: 48px 0;
  background-color: #1a304e;
  background-image: url(//cdn.shopify.com/s/files/1/1152/3264/t/25/assets/neat-background-product.png?1816012…);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  text-align: center;

  font-size: 2.0625rem;
  font-weight: 700;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100vw;

  transition: color 0.2s ease-in-out;
  color: #fff;
  display: block;
  line-height: 1.3;
  margin-bottom: 25px;
  text-decoration: none;
`;

const Return = styled(Link)`
  text-decoration: underline;
  color: #008cba;
  line-height: inherit;

  &:hover {
    color: #0078a0;
  }
`;

const Title = styled.h1`
  line-height: 1.3;
  text-align: center;
  font-family: "Inter";

  font-size: 40px;
  font-weight: 700;
  margin: 0;
`;

class Index extends React.Component {
  render() {
    return (
      <div>
        <AppContent>
          <PageTitle>
            <Title>Page Not Found</Title>
          </PageTitle>
          <Container>
            <Content>
              <p>
                Oops! Something went wrong and we couldn't find the page you
                were looking for.
              </p>
              <Return href="http://ergodox-ez.com/">Return to Home</Return>
            </Content>
          </Container>
        </AppContent>
      </div>
    );
  }
}

export default Index;
