export const fontFamily = '"Inter", sans-serif';

export const fontBlack = "#10151b";
export const fontClear = "#ffffff";
export const fontClearOpacity = "rgba(255,255,255,0.8)";
export const fontFooter = "rgba(255, 255, 255, 0.76)";
export const fontHighlight = "#fbdf7c";
export const fontDisabled = "#848E99";

export const bgDark = "#282c31";
export const bgDarker = "#22262b";
export const menuBgColor = "#e1e5e8";
export const buttonBgColor = "#fbdf7c";
export const buttonFontColor = "#22262b";
export const footerBottomColor = "#282C31";
export const bgOffCanvas = "rgba(0,0,0, 0.8)";

export const functionTransition = "ease-in-out";
export const timeTransition = "200ms";
export const opacityTransition = `${timeTransition} opacity ${functionTransition}`;
export const colorTransition = `${timeTransition} color ${functionTransition}`;
export const borderTransition = `${timeTransition} border ${functionTransition}`;
export const canvasTransition = `0.4s linear transform`;

export const pageContentFontColor = "#222";
export const pageBackgroundClear = "#fff";
export const startedContentFontColor = "#565656";
export const startedBackgroundClear = "#fff";
export const startedBackgroundGrey = "#f8f9f9";
export const startedBackgroundBlue = "#f1f6fa";
export const startedBackgroundAccent = "#faf7f1";
export const startedBorderColor = "#f6f0e3";
export const pageSelectedBgColor = "#e75e37";
export const pageLinkColor = "#008CBA";

export const cartItemTitleColor = fontClearOpacity;
export const cartItemDetailsColor = fontClearOpacity;
export const cartItemRemoveColor = "#c94d4d";
export const cartItemControlsColor = fontClearOpacity;
export const cartItemControlsBorder = "#5E656D";
export const cartItemControlsBg = "#181C21";
export const cartBorderColor = "rgba(255,255,255,0.4)";
export const cartTotalBg = "#1E2125";
export const cartTotalFont = fontClearOpacity;
export const cartBg = bgDark;
export const cartButton = buttonBgColor;
export const cartTitleColor = "#fff";
export const cartNotes = "#ABADAF";
export const cartNotesAreaBg = "#181C21";

export const controlActiveFontColor = "rgba(255, 255, 255, 0.95)";
export const controlFontColor = "#B2C4D4";
export const controlActiveBackground =
  "linear-gradient(180deg, rgba(82, 111, 134, 0.26) 0%, rgba(27, 44, 61, 0.26) 100%) #202a35";
export const controlBackground = "rgba(0, 0, 0, 0.24)";
export const controlExpertBackground = "#0e1219";
export const controlExpertButtonColor = "#c9c9c9";
export const controlDetailsColor = "#F3F3F3";

export const offCanvasMenuWidth = "320px";
export const offCanvasMenuWidthSmall = "250px";
export const offCanvasCartWidth = "432px";

export const mediaTabletLarge = "max-width : 1040px";
export const mediaTablet = "max-width : 768px";
export const mediaMobileLarge = "max-width : 568px";
export const mediaMobile = "max-width : 475px";
export const mediaDesktopSmallest = "max-width : 500px";
export const mediaMobileSmall = "max-width : 320px";
export const mediaShowMenu = "min-width: 881px";
export const mediaHideMenu = "max-width: 880px";
