import React from "react";
import styled from "styled-components";
import * as StyleVars from "./../styles/cssVariables";

const ContainerWrapper = styled.div`
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1180px) {
    padding: 0 16px;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  @media (${StyleVars.mediaMobile}) {
    padding: 0;
  }
`;

class Container extends React.Component {
  render() {
    return <ContainerWrapper>{this.props.children}</ContainerWrapper>;
  }
}

export default Container;
